import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter
} from "react-icons/ai";
import { SiGooglescholar } from "react-icons/si";

import { FaLinkedinIn } from "react-icons/fa";

function Socials() {
  return (
    <div>
      <li className="social-icons">
        <a
          href="https://github.com/angela-sha"
          style={{ color: "white" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillGithub />
        </a>
      </li>
      <li className="social-icons">
        <a
          href="https://x.com/angelasha_"
          style={{ color: "white" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineTwitter />
        </a>
      </li>
      <li className="social-icons">
        <a
          href="https://www.linkedin.com/in/angela-yuxin-sha-9ab44a161/"
          style={{ color: "white" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn />
        </a>
      </li>
      <li className="social-icons">
        <a
          href="https://scholar.google.com/citations?user=kXmem00AAAAJ&hl=en&oi=sra"
          style={{ color: "white" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SiGooglescholar />
        </a>
      </li>
    </div>

  )
}

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Container>
        <Row>
          <Col 
            sm={0}
            md={8}>
          </Col>
          <Col className="footer-body" md={4} sm={12}>
            <ul className="footer-icons"><Socials /></ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Footer;
