import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import face from "../../Assets/about.png";
// import Socials from "../../components/Footer";

function About() {
  return (
    <Container fluid className="about-section">
      <Container style={{ width: "90%" }}>
        <Row style={{ justifyContent: "center", paddingTop: "3vh" }}>
          <Col
            sm={12}
            md={5}
            style={{
              justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: "1em", fontWeight: 300, paddingBottom: "20px" }}>
              I am an incoming MSc student at the University of Toronto working on problems of machine learning 
              robustness and security. My fascination with the intersection of computer science
              and visual arts means I'm excited by different opportunities to create- whether it's working on
              research problems, in the kitchen, building products, or painting on a canvas.<br />
              <br />
              I started undergrad a molecular engineering major, excited about nanoparticle materials. Since
              then, my research has changed a lot: I was more interested in high performance computing and maths
              that model physical processes, which led me to computer science, statistics,
              and eventually AIML. <br /><br />

              I've worked on QA and entity linking for 3 years at Apple, in addition to TTS and CV/graphics problems 
              in the <a id="link" href="https://www.linkedin.com/posts/anachavarria_aiml-rotation-program-summer-2024-careers-activity-7117988483117318144-vssW/?trk=public_profile_like_view">AI/ML rotation program</a>
              and internships.
              Prior, I was researching adversarial machine learning at the SAND Lab (advised by{" "} 
              <a id="link" href="http://people.cs.uchicago.edu/~ravenben/">Ben Zhao</a>).<br /><br />
              
              As a Canadian-Chinese who grew up going to American schools, I cook fusion food,
              love travelling, and am bilingual but sometimes forget both languages.
              I also love to: <br /><br/>
              <ul style={{ listStylePosition: "inside" }}>
                <li>
                  host dinner parties, <Link to="/bite-photography">design around food</Link>
                </li>
                <li>
                  oil paint & take photos
                </li>
                <li>
                  hang out with my pup Harper
                </li>
              </ul>

            </h1>
          </Col>
          <Col
            sm={12}
            md={4}
            style={{}}
            className="about-img"
          >
            <img src={face} alt="about" className="img-fluid" style={{ maxHeight: "25em", paddingLeft: "20%" }} />
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default About;
