import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import sweetPotato from "../../Assets/FoodStyling/sweetPotato.png";
import squashCover from "../../Assets/FoodStyling/squashCover.png";
import squashInside from "../../Assets/FoodStyling/squashInside.png";
import miso from "../../Assets/FoodStyling/miso.png";

function FoodStyling() {
    return (
        <Container fluid className="about-section">
            <Container style={{ width: "80%" }}>
                <Row style={{
                    justifyContent: "center",
                }}>
                    <Col xs={12} sm={12} md={6}>
                        <img src={squashCover} alt="bite-squash-cover" className="img-fluid" style={{ maxHeight: "25em", paddingLeft: "15%", paddingBottom: "25px" }} />
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <img src={miso} alt="sweet-potato-pie" className="img-fluid" style={{ maxHeight: "25em", paddingBottom: "25px" }} />
                    </Col>
                </Row>
                <Row
                    style={{
                        paddingBottom: "50px",
                    }}>
                    <Col xs={12} sm={12} md={6}>
                        <img src={squashInside} alt="squash" className="img-fluid" style={{ maxHeight: "25em", paddingTop: "50px", paddingBottom: "25px" }} />
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <img src={sweetPotato} alt="squash" className="img-fluid" style={{ maxHeight: "25em", paddingLeft: "15%" }} />
                    </Col>
                </Row>

            </Container>
        </Container>
    );
}

export default FoodStyling;
