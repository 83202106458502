import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Typewriter } from 'react-simple-typewriter';

import Projects from "../Projects/Projects";
import Footer from "../../components/Footer";

function Home() {
  const researchRef = useRef(null);
  const scrollToResearch = (event) => {
    researchRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const engineeringRef = useRef(null);
  const scrollToProjects = (event) => {
    engineeringRef.current?.scrollIntoView({ behavior: 'smooth' });
  }
  

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col xs={11} sm={11} md={6} className="home-header">
            <h1 style={{ paddingBottom: 15 }} className="heading-name">
            Hi, I'm Angela.{" "}
            I'm from <Typewriter words={["Shanghai.", "Toronto.", "Chicago."]}
                  loop={0} deleteSpeed={100} typeSpeed={70} /><br />
            @ <a href="https://www.utoronto.ca">University of Toronto</a>, <a href="https://vectorinstitute.ai">Vector Institute</a> ca. Sep '25
            </h1>
                
                <h1 style={{ paddingBottom: 15 }} className="heading">
                
                Researching robustness and security of machine learning systems. 
                Previously working on QA and search as a MLE @ Apple; 
                studying computer science & statistics at <a>UChicago</a>;
                breaking machine learning systems at <a href="http://sandlab.cs.uchicago.edu">SAND Lab</a>.<br /><br />
              </h1>
              <h3 className="homelinks">
                <a onClick={scrollToResearch}>Research</a><br />
                <a onClick={scrollToProjects}>Engineering</a><br />
                <Link to="/about">About me</Link><br />
                <a href="https://www.linkedin.com/in/angela-yuxin-sha-9ab44a161/">Connect</a>
              </h3>



            </Col>

            <Col md={6} style={{ paddingBottom: 20 }}>
              {/* <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              /> */}
            </Col>
          </Row>
        </Container>
      </Container>
      <Projects engineeringRef={engineeringRef} researchRef={researchRef}/>
      <Footer />
    </section >
  );
}

export default Home;
