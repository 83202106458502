import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";

import handcrafting from "../../Assets/Projects/handcrafting.png";
import speechattack from "../../Assets/Projects/speechattack.png";
import vgg from "../../Assets/Projects/vgg.png";
import sba from "../../Assets/Projects/sba.png";
import tts from "../../Assets/Projects/tts.png";
import robustnessQA from "../../Assets/Projects/robustnessQA.png";
// import nano from "../../Assets/Projects/nano.png";
// import chess from "../../Assets/Projects/chess.png";
// import antibiotic from "../../Assets/Projects/antibiotic.png";
import thesis from "../../Assets/thesis_draft.pdf";

function Projects({ engineeringRef, researchRef }) {
  return (
    <Container ref={researchRef} fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          <span style={{ color: "#0008ff" }}>01 </span>AI+ML security, robustness
        </h1>
        <p id="descriptions">
          Adversarial machine learning, robustness, and interpretability research.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={handcrafting}
              hasCode={true}
              hasPaper={true}
              title="Algorithmically Inserted Backdoors for Neural Network Classifiers"
              description="Algorithm targeting latent space manipulation to hand-tune parameters for inserting backdoors in neural network classifiers."
              paperLink={thesis}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={speechattack}
              hasCode={true}
              hasPaper={true}
              title="Deep Learning Speech Synthesis Attacks"
              description="Generation and analysis of speech synthesis attacks on real world systems (Azure, Alexa, Google Home, WeChat). ACM CCS 2021."
              paperLink="https://arxiv.org/abs/2109.09598"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vgg}
              hasCode={false}
              hasPaper={false}
              title="Facial Recognition Security"
              description="Defense mechanism experiments for attacks on facial recognition CNNs."
              // paperLink={thesis}
            />
          </Col>

        </Row>
      </Container>

      <Container ref={engineeringRef}>
        <h1 className="project-heading">
          <span style={{ color: "#0008ff" }}>02 </span>ML Engineering
        </h1>
        <p id="descriptions">
          Products from my time at Apple.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sba}
              hasCode={false}
              hasDemo={true}
              title="Safari Highlights Entity Linking & Disambiguation"
              description="Entity linking reranker model training and deployment for Safari Highlights."
              demoLink="https://www.youtube.com/live/RXeOiIDNNek?si=uC0ksUUhLQeeeu4z&t=3488"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tts}
              hasCode={false}
              title="Generative Models for Siri Text-to-Speech"
              description="Benchmarking a new architecture with flow-based and GAN-based model architectures for Siri text-to-speech."
              demoLink="https://arxiv.org/abs/2109.09598"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={robustnessQA}
              hasCode={false}
              title="Robustness of QA Systems"
              description="Evaluation of and benchmarking robustness of QA systems (factoid, open-domain QA)."
              ghLink="https://github.com/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

        </Row>
      </Container>
      
      {/* <Container>
        <h1 className="project-heading">
          <span style={{ color: "#0008ff" }}>03 </span>other.. {"☺"}
        </h1>
        <p id="descriptions">
          Miscellaneous projects I've worked on in chemistry/statistics (for fun & before I was a MLE).
        </p>
        <Row>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chess}
              hasCode={true}
              title="Random Chess Generator"
              description="Python simulator that generates chess game of randomly selected moves and corresponding statistical analysis."
              ghLink="https://github.com/angela-sha/random-chess"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nano}
              hasCode={true}
              title="Nanoparticle Simulations"
              description="Interface to run nanoparticle simulations and analyze data for lab members."
              ghLink="https://github.com/angela-sha/nanofilm-sim"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={antibiotic}
              hasCode={false}
              title="Silver Nanoparticle-Antibiotic Synergy"
              description="Research project designing synergistic nanoparticle solution to antibiotic resistance. Intel ISEF Finalist 2017."
              demoLink="https://issuu.com/uchicagotth/docs/spring_19_updated_5_29"
            />
          </Col>
        </Row>
      </Container> */}
    </Container>
  );
}

export default Projects;